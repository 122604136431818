import React from 'react';
import { Nav } from 'react-bootstrap';
import {useAppDispatch} from "../../redux/hooks/hooks";
import {clearFilters} from "../../redux/features/filtersSlice";

interface Props {
    setHrDashboardSwitch: React.Dispatch<React.SetStateAction<boolean>>;
    hrDashboardSwitch: boolean;
    availableStudentsVariant: string;
}

const AvailableStudentsNavigation = (props: Props) => {
    const dispatch = useAppDispatch();
    const handleClearFilters = () => {
        dispatch(clearFilters());
    };
  return (
    <Nav className={`navigation`} variant="tabs" defaultActiveKey={`${props.hrDashboardSwitch ? '2' : '1'}`}>
      <Nav.Item className={`nav-items`}>
        <Nav.Link onClick={() => {
            return (
                handleClearFilters(),
                props.setHrDashboardSwitch(false)
            )

        }} className={'p-4'} eventKey="1">
          Dostępni kursanci
        </Nav.Link>
      </Nav.Item>
        {
            props.availableStudentsVariant === 'admin-list' ?
                <Nav.Item className={`nav-items`}>
                    <Nav.Link onClick={() => {
                        return (
                            handleClearFilters(),
                                props.setHrDashboardSwitch(true)
                        )

                    }} className={'p-4'} eventKey="2">
                        Zatrudnieni kursanci
                    </Nav.Link>
                </Nav.Item>
                :
                <Nav.Item className={`nav-items`}>
                    <Nav.Link onClick={() => {
                        return (
                            handleClearFilters(),
                                props.setHrDashboardSwitch(true)
                        )

                    }} className={'p-4'} eventKey="2">
                        Zarezerwowani kursanci
                    </Nav.Link>
                </Nav.Item>
        }
    </Nav>
  );
};

export { AvailableStudentsNavigation };
