import React, { useEffect, useState } from 'react';
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Spinner,
} from 'react-bootstrap';
import './Header.css';
import { useAppSelector } from '../../redux/hooks/hooks';
import { Link } from "react-router-dom";
import JjLogo from '../../assets/justjoin-icon/4.png';
import { apiUrl } from '../../config/api';

interface ResGitHub {
  name?: string;
  avatar_url?: string;
}
const Header = () => {
  const [loading, setLoading] = useState(false);
  const [resDataGitHub, setResDataGitHub] = useState<ResGitHub>();
  const gitHubUser = useAppSelector((state) => state.user.user);

  if (gitHubUser?.githubUsername) {
    useEffect(() => {
      setLoading(true);
      (async () => {
        try {
          const res = await fetch(
              `https://api.github.com/users/${gitHubUser?.githubUsername}`,
          );
          const resDataGitHub = await res.json();
          setResDataGitHub(resDataGitHub);
        }
        finally {
          setLoading(false);
        }
      })();
    }, []);
  }
  const avatar = (
    <img
      src={
        resDataGitHub?.avatar_url
          ? resDataGitHub.avatar_url
          : 'https://media.istockphoto.com/vectors/user-icon-flat-isolated-on-white-background-user-symbol-vector-vector-id1300845620?k=20&m=1300845620&s=612x612&w=0&h=f4XTZDAv7NPuZbG0habSpU0sNgECM0X7nbKzTUta3n8='
      }
      width="40"
      height="40"
      className="d-inline-block align-top navbar-color avatar"
      alt={
        resDataGitHub?.name === undefined
          ? 'avatar-domyślny'
          : 'avatar' + resDataGitHub.avatar_url
      }
      key={'user-avatar-key'}
    />
  );

  const name = ` ${gitHubUser?.firstName} ${gitHubUser?.lastName}`;

  const logOut = async () => {
    const res = await fetch(`${apiUrl}/auth/logout`, {
      credentials: 'include',
    });
    const resJson = await res.json();
    if (resJson.message === 'Wylogowano') {
      window.location.reload();
    }
  };

  return (
    <Container fluid className={`navbar-color p-0`}>
      <Navbar
        collapseOnSelect
        bg="dark"
        variant="dark"
        className={`navbar-color`}
        expand="md"
      >
        <Container className={`navbar-color container-header-second`}>
          <Navbar.Brand as={Link} to={'/dashboard'} className={`navbar-color`}>
            <img
              src={JjLogo}
              width="89"
              height="55"
              className="d-inline-block align-top navbar-color"
              alt="JustJob Logo"
            />
          </Navbar.Brand>
          <Nav>
            {loading ? (
              <Spinner animation="border" variant="danger" />
            ) : (
              <NavDropdown
                title={[avatar, name]}
                id="collasible-nav-dropdown"
                className={`text-white fs-4`}
              >
                <NavDropdown.Item
                  className={`text-white fs-5`}
                  as={Link} to={'/auth/user-form'}
                >
                  Konto
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  className={`text-white fs-5`}
                  onClick={logOut}
                >
                  Wyloguj
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
        </Container>
      </Navbar>
    </Container>
  );
};

export { Header };
