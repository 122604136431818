import React, { useState } from 'react';
import { Accordion, Button } from 'react-bootstrap';
import { apiUrl } from '../../../config/api';
import { InformationModal } from '../Modals/InformationModal/InformationModal';
import { FcApproval, FcCalendar } from 'react-icons/fc';
import Typist from 'react-typist';

interface Props {
  firstName: string;
  lastName: string;
  idStudent: string;
  status: string;
  hrID: string;
  setChangeStudentStatus: React.Dispatch<React.SetStateAction<boolean>>;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

interface DataDeactivationRes {
  message: string;
  status: boolean;
}

const AccordionHeaderStudents = (props: Props) => {
  const [show, setShow] = useState(false);
  const [showTypist, setShowTypist] = useState(false);
  const [dataFromRes, setDataFromRes] = useState<DataDeactivationRes>({ message: '', status: false });
  const reservedUserHandler = async (studentId: string) => {
    const res = await fetch(`${apiUrl}/hr/reserve/${studentId}`, {
      credentials: 'include',
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        hrID: props.hrID,
      }),
    });
    const dataDeactivationRes = await res.json();
    setDataFromRes(dataDeactivationRes);
  };

  return (
    <Accordion.Header className="accordion-header">
      <div style={{ width: '120px' }}>
        {props.firstName} {props.lastName}
      </div>
      {props.status === 'available' ? (
        <div className={'ms-4'}>
          <FcApproval
            className={'d-inline-block'}
            size={35}
            onMouseEnter={(e) => {
              e.stopPropagation();
              setShowTypist(true);
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              setShowTypist(false);
            }}
          />
          {showTypist && (
            <Typist className={'ms-2 d-inline-block'} avgTypingDelay={40} cursor={{ show: false }}>
              Jest w pełni dostępny i czeka na rezerwację.
            </Typist>
          )}
        </div>
      ) : (
        <div className={'ms-4 flex-column'}>
          <FcCalendar
            className={'d-inline-block'}
            size={35}
            onMouseEnter={(e) => {
              e.stopPropagation();
              setShowTypist(true);
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              setShowTypist(false);
            }}
          />
          {showTypist && (
            <Typist className={'ms-2 d-inline-block'} avgTypingDelay={40} cursor={{ show: false }}>
              Inny rekruter zainteresował się tym studentem.
            </Typist>
          )}
        </div>
      )}
      <div className="spacer"></div>
      <div>
        <Button
          className={'custom-button'}
          as={'div'}
          variant="danger"
          onClick={async (event) => {
            event.stopPropagation();
            await reservedUserHandler(props.idStudent);
            setShow(true);
          }}
        >
          {'Zarezerwuj rozmowę'}
        </Button>
      </div>
      {show && (
        <InformationModal
          message={dataFromRes.message}
          show={show}
          setShow={setShow}
          setChangeStudentStatus={props.setChangeStudentStatus}
          setSearch={props.setSearch}
        />
      )}
    </Accordion.Header>
  );
};

export { AccordionHeaderStudents };
