import React, { useState } from 'react';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';

import './UserLogin.css';
import { apiUrl } from '../../config/api';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../redux/hooks/hooks';
import { validateCurrUserAsync } from '../../redux/features/userSlice';
import { Link } from 'react-router-dom';
import JjLogo from '../../assets/justjoin-icon/4.png';

interface FormLoginType {
  email: string;
  password: string;
}

const UserLogin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormLoginType>();
  const [loading, setLoading] = useState(false);
  const [resError, setResError] = useState('');
  const onSubmit: SubmitHandler<FormLoginType> = async (data) => {
    setLoading(true);
    try {
      const res = await fetch(`${apiUrl}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...data,
        }),
        credentials: 'include',
      });
      const dataLoginRes = await res.json();
      if (dataLoginRes.statusCode !== 200) {
        setResError(dataLoginRes.message);
      } else {
        await dispatch(validateCurrUserAsync());
        navigate('/dashboard');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container className={`position-absolute top-50 start-50 translate-middle container-user-login`}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <img src={JjLogo} width="124px" height="76px" className="mx-auto d-block mb-5" alt="JustJob Logo" />

          <Form.Group as={Row} className="mb-3 mt-3" controlId="formHorizontalEmail">
            <Col sm={12}>
              <Form.Control
                className={'text-light'}
                type="text"
                placeholder="E-mail"
                {...register('email', {
                  required: `To pole nie może być puste!`,
                  maxLength: 255,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Zły format adresu E-mail.',
                  },
                })}
              />
              {errors.email && <p className={`errorP mt-1`}>{errors.email.message}</p>}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
            <Col sm={12}>
              <Form.Control
                className={'text-light'}
                type="password"
                placeholder="Hasło"
                {...register('password', {
                  required: `To pole nie może być puste!`,
                })}
              />

              {errors.password && <p className={`errorP mt-1`}>{errors.password.message}</p>}
              {resError !== '' && <p className={`errorP mt-1`}>{`${resError}`}</p>}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 mt-5">
            <Col sm={{ span: 12 }}>
              <Button className={`float-end `} id="buttonLogin" variant="danger" type="submit" disabled={loading}>
                {loading && (
                  <Spinner
                    as="span"
                    variant="danger"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className={'me-2 ms-0'}
                    id={'loading-spinner'}
                  />
                )}
                {loading ? 'Logowanie...' : 'Zaloguj'}
              </Button>
              <p className={'mt-1 text-light'}>
                <span>Zapomniałeś hasła?</span>{' '}
                <Link id={'register-a'} to={'/auth/send-reset-email'}>
                  Zresetuj
                </Link>
              </p>
            </Col>
          </Form.Group>
        </Form>
      </Container>
    </>
  );
};

export { UserLogin };
