import React from 'react';
import { DashboardContainer } from '../../../components/common/DashboardContainer/DashboardContainer';
import { DashboardCard } from '../../../components/common/DashboardCard/DashboardCard';
import { AvailableStudents } from '../../../components/AvailableStudents/AvailableStudents';

export const HrDashboard = () => {
  return (
    <DashboardContainer>
      <DashboardCard>
        <AvailableStudents availableStudentsVariant={'available-list'}/>
      </DashboardCard>
    </DashboardContainer>
  );
};
