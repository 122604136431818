import React, { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { ContractType } from 'types';
import './StudentForm.css';
import { apiUrl } from '../../../config/api';
import { useAppSelector } from '../../../redux/hooks/hooks';
import { useNavigate } from 'react-router-dom';

interface FormRegisterType {
  id: string;
  firstName: string;
  lastName: string;
  tel: number;
  bio: string;
  githubUsername: string;
  expectedTypeWork: string;
  targetWorkCity: string;
  expectedContractType: string;
  canTakeApprenticeship: string | boolean;
  canTakeApprenticeship2: boolean;
  expectedSalary: number;
  monthsOfCommercialExp: number;
  education: string;
  workExperience: string;
  courses: string;
  portfolioUrls: string;
  teamProjectUrls: string;
}

interface Props {
  correctData?: boolean;
  currUserType?: string;
}

const StudentForm = (props: Props) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<FormRegisterType>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [resError, setResError] = useState('');

  const currUser = useAppSelector((state) => state.user.user);

  const objCopy = { ...currUser };
  if (objCopy?.githubUsername === null) {
    objCopy.githubUsername = '';
  }
  if (objCopy?.targetWorkCity === null) {
    objCopy.targetWorkCity = '';
  }
  if (objCopy?.expectedSalary === null) {
    objCopy.expectedSalary = 0;
  }
  if (objCopy?.expectedContractType === null) {
    objCopy.expectedContractType = ContractType.DEFAULT;
  }
  if (objCopy?.monthsOfCommercialExp === null) {
    objCopy.monthsOfCommercialExp = 0;
  }
  if (objCopy?.tel === null) {
    objCopy.tel = '';
  }
  if (objCopy?.education === null) {
    objCopy.education = '';
  }
  if (objCopy?.workExperience === null) {
    objCopy.workExperience = '';
  }
  if (objCopy?.courses === null) {
    objCopy.courses = '';
  }
  if (objCopy?.bio === null) {
    objCopy.bio = '';
  }
  const pRef = useRef<HTMLParagraphElement>(null);

  const onSubmit: SubmitHandler<FormRegisterType> = async (data) => {
    setLoading(true);
    try {
      const res = await fetch(`${apiUrl}/${props.currUserType}/update`, {
        credentials: 'include',
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...data,
          id: objCopy?.id,
        }),
      });
      const dataFormRes = await res.json();
      if (dataFormRes.statusCode !== 200) {
        setResError(dataFormRes.message);
        pRef.current?.focus();
      }
      if (dataFormRes.statusCode === 200) {
        window.location.reload();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container fluid className={` container-form-user mt-5 mb-3`}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <h2 className={'mt-3 mb-4 text-white'}>Dane Użytkownika: </h2>
          <Row>
            <Form.Group className="mb-4" as={Col} controlId="formHorizontalFirstName" md={12} lg>
              <Form.Label className={`text-white`}>Imię:</Form.Label>
              <Form.Control
                type="text"
                className={`input-password text-white`}
                defaultValue={props.correctData ? objCopy?.firstName : ''}
                {...register('firstName', {
                  required: 'To pole nie może być puste!',
                  maxLength: {
                    value: 100,
                    message: 'Imię nie może być dłuższe niz 100 znaków.',
                  },
                  minLength: {
                    value: 3,
                    message: 'Imię nie może być krótsze niż 3 znaki',
                  },
                })}
              />
              {errors.firstName && <p className={`errorP mt-1`}>{errors.firstName.message}</p>}
              {resError === 'Nazwa użytkownika musi mieścić się w przedziale od 3 do 100 znaków.' && (
                <p tabIndex={0} ref={pRef} className={`errorP mt-1`}>{`${resError}`}</p>
              )}
              {resError === 'Nazwa użytkownika nie może być pusta' && <p tabIndex={0} ref={pRef} className={`errorP mt-1`}>{`${resError}`}</p>}
            </Form.Group>

            <Form.Group className="mb-4" as={Col} controlId="formHorizontalLastName" md={12} lg>
              <Form.Label className={`text-white`}>Nazwisko:</Form.Label>
              <Form.Control
                type="text"
                defaultValue={props.correctData ? objCopy?.lastName : ''}
                className={`input-password text-white`}
                {...register('lastName', {
                  required: 'To pole nie może być puste!',
                  maxLength: {
                    value: 100,
                    message: 'Nazwisko nie może być dłuższe niz 100 znaków.',
                  },
                  minLength: {
                    value: 3,
                    message: 'Nazwisko nie może być krótsze niż 3 znaki',
                  },
                })}
              />
              {errors.lastName && <p className={`errorP mt-1`}>{errors.lastName.message}</p>}
              {resError === 'Nazwisko użytkownika musi mieścić się w przedziale od 3 do 100 znaków.' && (
                <p tabIndex={0} ref={pRef} className={`errorP mt-1`}>{`${resError}`}</p>
              )}
              {resError === 'Nazwisko użytkownika nie może być pusta' && <p tabIndex={0} ref={pRef} className={`errorP mt-1`}>{`${resError}`}</p>}
            </Form.Group>

            <Form.Group className="mb-4" as={Col} controlId="formHorizontalGitHub" md={12} lg>
              <Form.Label className={`text-white`}>Nazwa Użytkonika GitHub:</Form.Label>
              <Form.Control
                type="text"
                defaultValue={props.correctData ? objCopy?.githubUsername : ''}
                className={`input-password text-white`}
                {...register('githubUsername', {
                  required: 'To pole nie może być puste!',
                  maxLength: {
                    value: 39,
                    message: 'Nazwa nie może być dłuższa niz 39 znaków.',
                  },
                  minLength: {
                    value: 3,
                    message: 'Nazwa nie może być krótsza niz 3 znaków.',
                  },
                })}
              />
              {errors.githubUsername && <p className={`errorP mt-1`}>{errors.githubUsername.message}</p>}
              {resError === 'Nazwa użytkownika GitHub musi mieścić się w przedziale od 3 do 39 znaków.' && (
                <p tabIndex={0} ref={pRef} className={`errorP mt-1`}>{`${resError}`}</p>
              )}
              {resError === 'Nazwa użytkownika GitGub nie może być pusta' && <p tabIndex={0} ref={pRef} className={`errorP mt-1`}>{`${resError}`}</p>}
              {resError === 'Internal server error' && (
                <p tabIndex={0} ref={pRef} className={`errorP mt-1`}>{`Taka nazwa użytkownika GitHub już istnieje w bazie.`}</p>
              )}
            </Form.Group>
          </Row>
          {props.currUserType === 'student' && (
            <>
              <Row>
                <Form.Group className="mb-4" as={Col} controlId="formHorizontalTargetCity" md={12} lg>
                  <Form.Label className={`text-white`}>Docelowe miasto, gdzie chce pracować kandydat:</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={props.correctData ? objCopy?.targetWorkCity : ''}
                    className={`input-password text-white`}
                    {...register('targetWorkCity', {
                      maxLength: {
                        value: 100,
                        message: 'Nazwa nie może być dłuższa niz 100 znaków.',
                      },
                      minLength: {
                        value: 3,
                        message: 'Nazwa nie może być krótsza niz 3 znaków.',
                      },
                    })}
                  />
                  {errors.targetWorkCity && <p className={`errorP mt-1`}>{errors.targetWorkCity.message}</p>}
                  {resError === 'Nazwa miasta musi mieścić się w przedziale od 3 do 100 znaków.' && (
                    <p tabIndex={0} ref={pRef} className={`errorP mt-1`}>{`${resError}`}</p>
                  )}
                </Form.Group>

                <Form.Group className="mb-4" as={Col} controlId="formHorizontalTypeWork" md={12} lg>
                  <Form.Label className={`text-white`}>Wybór preferowanego miejsca pracy:</Form.Label>
                  <Form.Select defaultValue={props.correctData ? objCopy?.expectedTypeWork : 'Dowolne'} {...register('expectedTypeWork')}>
                    <option>Dowolne</option>
                    <option>Zdalnie</option>
                    <option>Biuro</option>
                    <option>Gotowość do przeprowadzki</option>
                    <option>Hybrydowo</option>
                  </Form.Select>
                  {resError ===
                    'Preferowane miejsce pracy musi być wybrane między: Biuro, Zdalnie, Dowolne, Gotowość do przeprowadzki, Hybrydowo' && (
                    <p tabIndex={0} ref={pRef} className={`errorP mt-1`}>{`${resError}`}</p>
                  )}
                </Form.Group>

                <Form.Group className="mb-4" as={Col} controlId="formHorizontalContractType" md={12} lg>
                  <Form.Label className={`text-white`}>Oczekiwany typ kontraktu:</Form.Label>
                  <Form.Select defaultValue={props.correctData ? objCopy?.expectedContractType : 'Dowolny'} {...register('expectedContractType')}>
                    <option>Dowolny</option>
                    <option>UoP</option>
                    <option>B2B</option>
                    <option>UZ</option>
                    <option>UoD</option>
                  </Form.Select>
                  {resError === 'Oczekiwany typ kontraktu musi być wybrane pomiędzy: Dowolny, B2B, UoD, UZ, UoP' && (
                    <p tabIndex={0} ref={pRef} className={`errorP mt-1`}>{`${resError}`}</p>
                  )}
                </Form.Group>
              </Row>

              <Row className="red-line pb-3">
                <Form.Group className="mb-4" as={Col} controlId="formHorizontalSalary" md={12} lg>
                  <Form.Label className={`text-white mt-4`}>Oczekiwane wynagrodzenie miesięczne netto:</Form.Label>
                  <Form.Control
                    type="number"
                    defaultValue={props.correctData ? objCopy?.expectedSalary : ''}
                    className={`input-password text-white`}
                    {...register('expectedSalary', {
                      max: {
                        value: 999999,
                        message: 'Liczba jest ograniczona do 999999',
                      },
                      min: {
                        value: 0,
                        message: 'Liczba nie może być ujemna',
                      },
                    })}
                  />
                  {errors.expectedSalary && <p className={`errorP mt-1`}>{errors.expectedSalary.message}</p>}
                  {resError === 'Wynagrodzenie nie może być większe nic 999999 oraz nie może być ujemne.' && (
                    <p tabIndex={0} ref={pRef} className={`errorP mt-1`}>{`${resError}`}</p>
                  )}
                </Form.Group>

                <Form.Group className="mb-4" as={Col} controlId="formHorizontalExp" md={12} lg>
                  <Form.Label className={`text-white `}>Ilość miesięcy doświadczenia komercyjnego kandydata w programowaniu:</Form.Label>
                  <Form.Control
                    type="number"
                    defaultValue={props.correctData ? objCopy?.monthsOfCommercialExp : 0}
                    className={`input-password text-white`}
                    {...register('monthsOfCommercialExp', {
                      required: 'To pole nie może być puste!',
                      min: {
                        value: 0,
                        message: 'Liczba nie może być ujemna',
                      },
                      max: {
                        value: 999,
                        message: 'Liczba jest ograniczona do 999',
                      },
                    })}
                  />
                  {errors.monthsOfCommercialExp && <p className={`errorP mt-1`}>{errors.monthsOfCommercialExp.message}</p>}
                  {resError === 'Wynagrodzenie nie może być większe nic 999 oraz nie może być ujemne.' && (
                    <p tabIndex={0} ref={pRef} className={`errorP mt-1`}>{`${resError}`}</p>
                  )}
                  {resError === 'Ilość miesięcznego doświadczenia nie może być puste' && (
                    <p tabIndex={0} ref={pRef} className={`errorP mt-1`}>{`${resError}`}</p>
                  )}
                </Form.Group>
                <Form.Group className="mb-4" as={Col} controlId="formHorizontalNumber" md={12} lg>
                  <Form.Label className={`text-white mt-4`}>Numer telefonu:</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={props.correctData ? objCopy?.tel : ''}
                    className={`input-password text-white`}
                    {...register('tel', {
                      maxLength: {
                        value: 14,
                        message: 'Numer telefonu nie może przekraczać 14 cyfr.',
                      },
                      minLength: {
                        value: 9,
                        message: 'Numer telefonu musi mieć minimmalnie 9 cyfr',
                      },
                    })}
                  />
                  {errors.tel && <p className={`errorP mt-1`}>{errors.tel.message}</p>}
                  {resError === 'Numer telefonu nie może być dłuższy niż 14 znaków oraz krótszy od 9 znaków.' && (
                    <p tabIndex={0} ref={pRef} className={`errorP mt-1`}>{`${resError}`}</p>
                  )}
                </Form.Group>
              </Row>
              <Row className="mt-4">
                <Form.Group className="mb-4" as={Col} controlId="formHorizontalProject" md={12} lg>
                  <Form.Label className={`text-white`}>Project URL:</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={props.correctData ? objCopy?.teamProjectUrls : ''}
                    className={`input-password text-white`}
                    {...register('teamProjectUrls', {
                      required: 'To pole nie może być puste!',
                      maxLength: {
                        value: 255,
                        message: 'To pole nie może przekraczać 255 znaków.',
                      },
                      minLength: {
                        value: 2,
                        message: 'To pole nie może być mniejsze niż 2 znaki.',
                      },
                    })}
                  />
                  <Form.Text className="text-muted">
                    Jeśli chcesz dodać więcej niż jeden link, proszimmy oddzielić go przecinkiem:
                    <br />
                    https://www.ex1.pl,https://www.ex2.pl
                  </Form.Text>
                  {errors.teamProjectUrls && <p className={`errorP mt-1`}>{errors.teamProjectUrls.message}</p>}
                  {resError === 'Pole projekt grupowy nie może być puste' && <p tabIndex={0} ref={pRef} className={`errorP mt-1`}>{`${resError}`}</p>}
                  {resError === 'Pole projekt grupowy musi mieścić się w przedziale od 2 do 255 znaków.' && (
                    <p tabIndex={0} ref={pRef} className={`errorP mt-1`}>{`${resError}`}</p>
                  )}
                </Form.Group>
              </Row>
              <Row className="red-line pb-2">
                <Form.Group className="mb-4" as={Col} controlId="formHorizontalPortfolio" md={12} lg>
                  <Form.Label className={`text-white`}>Portfolio URL:</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={props.correctData ? objCopy?.portfolioUrls : ''}
                    className={`input-password text-white`}
                    {...register('portfolioUrls', {
                      maxLength: {
                        value: 255,
                        message: 'To pole nie może przekraczać 255 znaków.',
                      },
                      minLength: {
                        value: 2,
                        message: 'To pole nie może być mniejsze niż 2 znaki.',
                      },
                    })}
                  />
                  <Form.Text className="text-muted">
                    Jeśli chcesz dodać więcej niż jeden link, prosimy oddzielić go przecinkiem:
                    <br />
                    https://www.ex1.pl,https://www.ex2.pl
                  </Form.Text>
                  {errors.portfolioUrls && <p className={`errorP mt-1`}>{errors.portfolioUrls.message}</p>}
                  {resError === 'Pole Twoje portfolio musi mieścić się w przedziale od 2 do 255 znaków.' && (
                    <p tabIndex={0} ref={pRef} className={`errorP mt-1`}>{`${resError}`}</p>
                  )}
                </Form.Group>
              </Row>
              <Row className="mb-3 mt-4">
                <Form.Group className="mb-3" controlId="formTextAreaInformation1">
                  <Form.Label>Przebieg edukacji:</Form.Label>
                  <Form.Control
                    defaultValue={props.correctData ? objCopy?.education : ''}
                    as="textarea"
                    rows={2}
                    placeholder="Jakie były Twoje kroki by wejść do świata programowania..."
                    {...register('education')}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group className="mb-3" controlId="formTextAreaInformation2" defaultValue={props.correctData ? objCopy?.workExperience : ''}>
                  <Form.Label>Przebieg doświadczenia zawodowego:</Form.Label>
                  <Form.Control
                    defaultValue={props.correctData ? objCopy?.workExperience : ''}
                    as="textarea"
                    rows={2}
                    placeholder="Jeśli pracowałaś/ałeś w firmie związaną z programwoaniem, pochwal się..."
                    {...register('workExperience')}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group className="mb-3" controlId="formTextAreaInformation3">
                  <Form.Label>Kursy i certyfikaty związane z programowaniem:</Form.Label>
                  <Form.Control
                    defaultValue={props.correctData ? objCopy?.courses : ''}
                    as="textarea"
                    rows={2}
                    placeholder="Jakie certyfikaty oraz kursy ukończyłeś, wymień tylko te związane z programowaniem..."
                    {...register('courses')}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3 red-line pb-2">
                <Form.Group className="mb-3" controlId="formTextAreaInformation4">
                  <Form.Label>Biografia:</Form.Label>
                  <Form.Control
                    defaultValue={props.correctData ? objCopy?.bio : ''}
                    as="textarea"
                    rows={3}
                    placeholder="Napisz coś o sobie..."
                    {...register('bio')}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3 red-line pb-2">
                <Form.Label className={`text-white`}>Czy kandydat wyraża zgodę na odbycie bezpłatnych praktyk/stażu na początek ?</Form.Label>
                <Form.Group className="" controlId="formRadioCheck1">
                  <Form.Check defaultChecked={false} defaultValue={1} type="radio" label="Tak" {...register('canTakeApprenticeship')} />
                </Form.Group>
                <Form.Group className="mb-3 " controlId="formRadioCheck2">
                  <Form.Check defaultChecked={true} defaultValue={0} type="radio" label="Nie" {...register('canTakeApprenticeship')} />
                  {resError === 'Pole praktyki nie może być inne niż przewidział to zespół developerów.' && (
                    <p tabIndex={0} ref={pRef} className={`errorP mt-1`}>{`${resError}`}</p>
                  )}
                </Form.Group>
              </Row>
            </>
          )}
          <Row>
            <Form.Group className="mb-1 mt-2">
              <Button className={''} variant="danger" id={'button-change-password'} type="submit" disabled={loading}>
                {loading && (
                  <Spinner
                    as="span"
                    variant="danger"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className={'me-2 ms-0'}
                    id={'loading-spinner'}
                  />
                )}
                {loading ? 'Trwa zapisywanie danych...' : 'Zapisz swoje dane personalne'}
              </Button>
              {props.correctData && (
                <Button className={'float-end'} id={'button-change-password'} variant="secondary" onClick={() => navigate('/dashboard')}>
                  Anuluj
                </Button>
              )}
            </Form.Group>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export { StudentForm };
