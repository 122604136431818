import React from 'react';
import { UserLogin } from '../components/UserLogin/UserLogin';

const UserLoginSite = () => {
  return (
    <>
      <UserLogin />
    </>
  );
};

export { UserLoginSite };
