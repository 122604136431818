import React, { useEffect, useState } from 'react';
import './AvailableStudents.css';
import { apiUrl } from '../../config/api';

import { AvailableStudentsSearch } from './AvailableStudentsSearch';
import { AvailableStudentsNavigation } from './AvailableStudentsNavigation';
import { AvailableStudentsTableElements } from './AvailableStudentsTableElements';
import { PaginationStudents } from './PaginationStudents';
import { useAppSelector } from '../../redux/hooks/hooks';

interface UserListResponseHr {
  id: string;
  email: string;
  courseCompletion: number;
  courseEngagement: number;
  projectDegree: number;
  teamProjectDegree: number;
  expectedTypeWork: string;
  targetWorkCity: string;
  expectedContractType: string;
  expectedSalary: string;
  canTakeApprenticeship: string;
  monthsOfCommercialExp: string;
  firstName: string;
  lastName: string;
  status: string;
  githubUsername: string;
  date: string;
}

interface Props {
  availableStudentsVariant: string;
}

const AvailableStudents = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [changeStudentStatus, setChangeStudentStatus] = useState(false);
  const [resDataUserList, setResDataUserList] = useState<UserListResponseHr[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [studentsListPerPage, setStudentsListPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [hrDashboardSwitch, setHrDashboardSwitch] = useState(false);
  const [dateStatus, setDateStatus] = useState<{ message: string }>({
    message: '',
  });
  const [dateReserved, setDateReserved] = useState<{ date: string }[]>([
    {
      date: '',
    },
  ]);
  const hrID = useAppSelector((state) => state.user.user!.id);
  const filtersQuery = useAppSelector((state) => state.filters.filtersSet.queryString);

  const filteredBySearch = resDataUserList.filter((filterData) => {
    filterData.firstName === null ? (filterData.firstName = '') : filterData.firstName;
    filterData.lastName === null ? (filterData.lastName = 'BRAK') : filterData.lastName;
    return filterData.firstName.toLowerCase().includes(search.toLowerCase()) || filterData.lastName.toLowerCase().includes(search.toLowerCase());
  });

  const indexOfLastStudentsList = currentPage * studentsListPerPage;
  const indexOfFirstStudentsList = indexOfLastStudentsList - studentsListPerPage;

  let currentStudentsList = filteredBySearch.slice(indexOfFirstStudentsList, indexOfLastStudentsList);

  if (dateReserved[0].date !== '' && hrDashboardSwitch) {
    currentStudentsList = currentStudentsList.map((data, index) => {
      return {
        ...data,
        date: dateReserved[index].date,
      };
    });
  }

  if (hrDashboardSwitch) {
    useEffect(() => {
      setLoading(true);
      setChangeStudentStatus(false);
      if (props.availableStudentsVariant === 'admin-list') {
        (async () => {
          try {
            const res = await fetch(`${apiUrl}/user/hired/all?${filtersQuery}`, {
              credentials: 'include',
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            });
            const data: UserListResponseHr[] = await res.json();
            setResDataUserList(data);
          } finally {
            setLoading(false);
          }
        })();
      } else {
        (async () => {
          try {
            const res = await fetch(`${apiUrl}/user/list/reserved?${filtersQuery}`, {
              credentials: 'include',
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                hrId: hrID,
              }),
            });
            const data: UserListResponseHr[] = await res.json();
            setResDataUserList(data);
            if (data.length > 0) {
              const res = await fetch(`${apiUrl}/user/list/reservedDate`, {
                credentials: 'include',
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  hrId: hrID,
                }),
              });
              const data: { date: string }[] = await res.json();
              const oldDate = data.filter((data) => new Date(data.date) < new Date());
              const upToDate = data.filter((data) => new Date(data.date) > new Date());
              upToDate.length > 0 && setDateReserved(data);

              if (oldDate.length > 0) {
                const res = await fetch(`${apiUrl}/user/list/reservedDate/filterOldDate`, {
                  credentials: 'include',
                  method: 'PATCH',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    oldDate,
                    hrId: hrID,
                  }),
                });
                const resData = await res.json();
                setDateStatus(resData);
              }
            }
          } finally {
            setLoading(false);
          }
        })();
      }
    }, [changeStudentStatus, hrDashboardSwitch, filtersQuery]);
  } else {
    useEffect(() => {
      setLoading(true);
      setChangeStudentStatus(false);
      (async () => {
        try {
          const res = await fetch(`${apiUrl}/user/list/basic?${filtersQuery}`, {
            credentials: 'include',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              hrId: hrID,
            }),
          });
          const data: UserListResponseHr[] = await res.json();
          setResDataUserList(data);
        } finally {
          setLoading(false);
        }
      })();
    }, [changeStudentStatus, hrDashboardSwitch, filtersQuery]);
  }

  return (
    <>
      <>
        <div className="list-container pt-0 ps-0">
          <AvailableStudentsNavigation
            setHrDashboardSwitch={setHrDashboardSwitch}
            hrDashboardSwitch={hrDashboardSwitch}
            availableStudentsVariant={props.availableStudentsVariant}
          />
        </div>
        <div className="list-container pb-5">
          <AvailableStudentsSearch setSearch={setSearch} search={search} />
          <AvailableStudentsTableElements
            userListResHr={currentStudentsList}
            setChangeStudentStatus={setChangeStudentStatus}
            availableStudentsVariant={props.availableStudentsVariant}
            hrDashboardSwitch={hrDashboardSwitch}
            hrID={hrID}
            setSearch={setSearch}
            dateStatus={dateStatus}
            setDateStatus={setDateStatus}
          />
        </div>
        <PaginationStudents
          studentsListPerPage={studentsListPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setStudentsListPerPage={setStudentsListPerPage}
          numberOfStudents={filteredBySearch.length}
          search={search}
          hrDashboardSwitch={hrDashboardSwitch}
        />
      </>
    </>
  );
};

export { AvailableStudents };
